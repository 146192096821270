/* palette based on: https://coolors.co/463f3a-8a817c-bcb8b1-f4f3ee-e0afa0 */

$small: 300px;
$medium: 900px;
$green: #a0e0af;
$red: #e0a0a0;

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

html {
  height: 100%;
}

body {
  margin: 0;
  color: #bcb8b1;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background: rgb(45, 19, 44);
  background: linear-gradient(
    141deg,
    rgba(45, 19, 44, 1) 0%,
    rgba(168, 67, 114, 1) 74%,
    rgba(87, 6, 142, 1) 100%
  );
  background-attachment: fixed; */
  background: #463f3a;
  padding: 0 1em 0 1em;
  @media screen and (min-width: $medium) {
    padding: 0 4em 0 4em;
  }
}

h1,
h2,
h3,
h4 {
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  font-weight: 800;
  color: #f4f3ee;
  text-align: center;
  margin: 0 0 0.1em;
}

h1 {
  color: #e0afa0;
  font-size: 200%;
  @media screen and (min-width: $medium) {
    font-size: 400%;
  }
}

h2 {
  font-size: 175%;
  @media screen and (min-width: $medium) {
    font-size: 300%;
  }
}

h2 .material-icons {
  font-size: 150%;
  @media screen and (min-width: $medium) {
    font-size: 200%;
  }
}

h3 {
  font-size: 150%;
  @media screen and (min-width: $medium) {
    font-size: 200%;
  }
}

h4 {
  color: #8a817c;
  font-size: 125%;
  @media screen and (min-width: $medium) {
    font-size: 150%;
  }
}

h1 .material-icons,
h2 .material-icons,
h3 .material-icons,
h4 .material-icons {
  font-size: 100%;
  position: relative;
  top: 0.15em;
  left: 0.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #e0afa0;
}

a:visited {
  color: #9f7b70;
}

button.as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #e0afa0;
  text-decoration: underline;
  cursor: pointer;
}

.env-dev {
  background-image: url('images/dev-bg.png');
  background-repeat: repeat;
}

.site {
  max-width: 1000px;
  margin: 10px auto;
}

select {
  color: #666;
  display: block;
  margin: 0 auto;
}

.spacer {
  height: 400px;
}

#state-select {
  display: block;
  font-size: 16px;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto',
    sans-serif;
  color: #463f3a;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 80%;
  @media screen and (min-width: $medium) {
    width: 40%;
  }

  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 1em auto;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
        for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
        
    */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
  /* Hide arrow icon in IE browsers */
  ::-ms-expand {
    display: none;
  }
  :hover {
    border-color: #888;
  }
  /* Focus style */
  :focus {
    border-color: #aaa;
    /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  /* Set options to normal weight */
  option {
    font-weight: normal;
  }

  // /* Support for rtl text, explicit support for Arabic and Hebrew */
  // *[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  //     background-position: left .7em top 50%, 0 0;
  //     padding: .6em .8em .5em 1.4em;
  // }

  /* Disabled styles */
  :disabled,
  [aria-disabled='true'] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  }

  :disabled:hover,
  [aria-disabled='true'] {
    border-color: #aaa;
  }
}

.candidate-search-form input,
.candidate-button {
  // fix me
  width: 100%;
  padding: 0.5em;
  font-size: 1.25em;
  @media screen and (min-width: $medium) {
    width: 100%;
    padding: 0.5em;
    font-size: 1.25em;
  }
}

.candidate-list-item {
  text-align: center;
  padding-bottom: 1em;
  border-bottom: 1px rgba(255, 255, 255, 0.25) solid;
  button i {
    margin-left: 0.5em;
  }
}

.modal {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #463f3a;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  @media screen and (min-width: $medium) {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
  }
  max-width: 900px;
  margin: 0 auto;
  overflow-x: hidden;
}

.candidate-modal-header {
  padding-bottom: 1em;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(70px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(70px);
}

.candidate-modal-header {
  background-color: #1f1c1a;
}

.supporter-field-container {
  width: 90%;
  margin: 0 auto;
  .copyable {
    overflow: hidden;
  }
}

.copyable {
  border: 1px #bcb8b1 solid;
  padding: 0.5em;
  margin: 0 auto 0.25em auto;
  cursor: copy;
  transition: all 100ms ease-in-out;
  background-color: rgba(255, 255, 255, 0.05);
  color: #bcb8b1;
  font-weight: 800;
  text-align: center;
  @media screen and (min-width: $medium) {
    margin: 0 auto 1em auto;
  }
}

.just-clicked {
  background-color: rgba(255, 255, 255, 0.75);
}

.supporter-fieldname {
  text-align: center;
  padding: 0;
  margin: 0;
}

.buy-phone-number {
  display: block;
  font-size: 120%;
  font-weight: 800;
  text-transform: uppercase;
  padding: 1em;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  @media screen and (min-width: $medium) {
    font-size: 180%;
  }
}

.candidate-url {
  margin: 1em auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  width: 90%;
  text-align: center;
  @media screen and (min-width: $medium) {
    font-size: 120%;
  }
  label {
    flex: 1 100%;
    text-transform: lowercase;
  }
  input {
    flex: 2 80%;
    text-align: center;
    @media screen and (min-width: $medium) {
      font-size: 100%;
    }
  }
  button {
    flex: 1 10%;
  }
  input {
    padding: 0.25em;
    &:disabled {
      background-color: transparent;
      color: #8a817c;
      border: none;
    }
  }
}

// .candidate-url input {
//   &:disabled {
//     background-color: transparent;
//     color: #8a817c;
//   }
// }

.candidate-url button {
  &:disabled {
    display: none;
  }
}

.supporter-name-container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  width: 90%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  .supporter-field-container {
    flex: 2 40%;
  }
  .supporter-name-combined {
    flex: 1 5%;
    .material-icons {
      align-items: center;
      padding: 0;
      margin: 0.75em auto 0 auto;
      @media screen and (min-width: $medium) {
        margin: 0.25em auto 0 auto;
      }
    }
    .copyable {
      display: inline-block;
      background-color: transparent;
      border: none;
      width: 100%;
      transition: all 100ms ease-in-out;
    }
    .just-clicked {
      background-color: rgba(255, 255, 255, 0.75);
    }
  }
}

.supporter-address-container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  width: 90%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  .supporter-field-container {
    margin: 0.5em;
    flex: 1 30%;
  }
  .supporter-address1 {
    flex: 3 100%;
    .copyable {
      width: 100%;
    }
  }
}

.candidate-name {
  i {
    margin-right: 0.5em;
    color: $green;
  }
  @media screen and (min-width: $medium) {
    font-size: 160%;
  }
}

i.error {
  color: $red;
}

.url-error {
  color: rgba(255, 255, 255, 0.9);
}

input.url-error {
  background-color: rgba(255, 30, 30, 0.25);
}

.message {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  border-bottom: 1px #000 solid;
  background-color: #bcb8b1;
  color: #1f1c1a;
  .material-icons {
    color: #1f1c1a;
  }
}

.message-icon {
  flex: 1 15%;
  margin: 0.25em 1em;
}

button.toggle-full-message {
  text-decoration: none;
}

.message-full-message {
  width: 100%;
  padding: 1em;
}

.hide-full .message-full-message {
  display: none;
}

.hide-full {
  .expand_more {
    display: inline;
  }
  .expand_less {
    display: none;
  }
}

.show-full {
  .expand_more {
    display: none;
  }
  .expand_less {
    display: inline;
  }
}
